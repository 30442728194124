import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
        >
            Tetrais
        </a>
          <input placeholder="Username" className="input" name="text" type="text"></input>
      </header>
    </div>
  );
}

export default App;
